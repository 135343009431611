import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { contact } from '@services/api'

import { isFieldValid } from '@utils/form'
import { withTranslation } from '@utils/i18n'

import Input from '@components/common/Input'
import Button from '@components/common/Button'
import Text from '@components/common/Text'

import css from './index.module.scss'
import Select from '@components/common/Select'

const Contact =({ t, onCloseModal }) => {
  const [errors, setErrors] = useState([])
  const [success, setSuccess] = useState('')
  const [modalOpen, setModalOpen] = useState(true)

  const [name, setName] = useState({ value: '', error: false })
  const [email, setEmail] = useState({ value: '', error: false })
  const [comments, setComments] = useState({ value: '', error: false })
  const [reason, setReason] = useState({ value: '', error: false })

  useEffect(() => {
    if(modalOpen === false) {
      onCloseModal && onCloseModal()
    }
  }, [modalOpen])

  const sendContactRequest = async () => {
    const errorsArray = []
    if (!isFieldValid('email', true, email.value)) {
      setEmail({ value: email.value, error: true })
      errorsArray.push(t('validations:invalid_email'))
    }
    if (!isFieldValid('text', true, name.value)) {
      setName({ value: name.value, error: true })
      errorsArray.push(t('validations:required'))
    }
    if (!isFieldValid('text', true, reason.value)) {
      setReason({ value: reason.value, error: true })
      errorsArray.push(t('validations:required'))
    }
    if (!isFieldValid('text', true, comments.value)) {
      setComments({ value: comments.value, error: true })
      errorsArray.push(t('validations:required'))
    }
    if (isFieldValid('email', true, email.value) && isFieldValid('text', true, name.value) && isFieldValid('text', true, reason.value) && isFieldValid('text', true, comments.value)) {
      setErrors([])
      const data = {
        name: name.value,
        email: email.value,
        comments: comments.value,
        reason: reason.value
      }
      contact(data).then(res => {
        setSuccess(t('validations:contact_success'))
      }).catch(() => {
        setErrors([t('validations:no_contact')])
      })
    } else {
      setErrors(errorsArray)
    }
  }

  const handleChange = (name, value) => {
    const stateValue = { value: value, error: false }
    if (name === 'name') setName(stateValue)
    if (name === 'email') setEmail(stateValue)
    if (name === 'reason') setReason(stateValue)
    if (name === 'comments') setComments(stateValue)
  }

  const errorsArray = errors.filter((error, idx, self) => error?.length && self.indexOf(error) === idx)

  return (
    <div className={css.container}>
      <Text variant="h3" align="center">Contáctanos</Text><br/>
      <form noValidate>
        <Input
          label={t('names')}
          name="name"
          autoFocus
          required
          value={name.value}
          error={name.error}
          onChange={handleChange}
        />
        <Input
          type="email"
          label={t('email')}
          name="email"
          required
          value={email.value}
          error={email.error}
          onChange={handleChange}
        />
        <Select
          label={t('reason')}
          name="reason"
          options={[
            {
              key: t('reason_1'),
              value: t('reason_1')
            },
            {
              key: t('reason_2'),
              value: t('reason_2')
            },
          ]}
          required
          value={reason.value}
          error={reason.error}
          onChange={handleChange}
        />
        <Input
          label={t('comments')}
          name="comments"
          required
          multiline
          rows="4"
          value={comments.value}
          error={comments.error}
          onChange={handleChange}
        />
        {errorsArray.map((error, idx) => {
          return (
            <div key={idx}>
              <Text component="p" color="error" variant="caption">{error}</Text>
            </div>
          )
        })}
        {success &&
          <div>
            <Text component="p" color="textPrimary" variant="body1">{success}</Text>
          </div>
        }
        <div className={css.actionsContainer}>
          <div className={css.buttonContainer}>
            <Button
              color="primary"
              size="large"
              onClick={() => success ? setModalOpen(false) : sendContactRequest()}
            >
              {success ? t('close') : t('send')}
            </Button>
          </div>
        </div>
      </form>
    </div> 
  )
}

Contact.getInitialProps = async () => ({
	namespacesRequired: ['common','validations']
})

Contact.propTypes = {
	t: PropTypes.func.isRequired
}

export default withTranslation(['common', 'validations'])(Contact)