import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from '@utils/i18n'

import Card from '@components/Card'
import Text from '@components/common/Text'
import Button from '@components/common/Button'
import BlockDescription from '@components/common/BlockDescription'
import Modal from '@components/Modal'
import Contact from '@components/Contact'

import css from './index.module.scss'

const UPDATE_PATH = `/images/update.png`
const ICON_PATH = `/images/more.png`
const COIN_PATH = `/images/coin.png`
const CHECK_PATH = `/images/check.png`
const VOLUME_PATH = `/images/volume.png`

const NotAutorizedHome = ({ t }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  return (
    <div className={css.containerContact}>
      <div className={css.contentPinguino}>
        <div className={css.imgVolume}>
          <img src={VOLUME_PATH} />
          <Text component="h1">{t('app_name')}</Text>
        </div>
        <div className={css.pSubTitle}>
          <Text component="p">{t('name_m')}</Text>
        </div>
        <Card style={['primaryColor', 'radius10', 'shadow']}>
          <div className={css.contentCard}>
            <div>
              <Text component="p" variant="h6" color="secondary">{t('pinguino_title')}</Text>
            </div>
            <div className={css.italic}>
              <Text component="p">{t('pinguino_text')}</Text>
            </div>
            <div className={css.italic}>
              <Text component="p">{t('pinguino_text1')}</Text>
            </div>
            <div className={css.italic}>
              <Text component="p">{t('pinguino_text2')}</Text>
            </div>
          </div>
        </Card>
      </div>
      <Card style={['secondaryColor', 'center', 'radius10']}>
        <div>
          <Text component="h2" variant="h4" color="terciary">
            {t('advantage')}
          </Text>
          <div className={css.containerBlockDescription}>
            <BlockDescription
              title={t('simple_title')}
              imgUrl={UPDATE_PATH}
              iconUrl={ICON_PATH}
              text={t('simple_text')}
            >
            </BlockDescription>
            <BlockDescription
              title={t('economic_title')}
              imgUrl={COIN_PATH}
              iconUrl={ICON_PATH}
              text={t('economic_text')}
            >
            </BlockDescription>
            <BlockDescription
              title={t('safe_title')}
              imgUrl={CHECK_PATH}
              iconUrl={ICON_PATH}
              text={t('safe_text')}
            >
            </BlockDescription>
          </div>
          {<div>
            <Button color="terciary" onClick={() => toggleModal()}>{t('contact_us')}</Button>
            <Modal
              width={500}
              content={<Contact  onCloseModal={() => toggleModal()}/>}
              isOpen={modalOpen}
              closeSymbol
            />
          </div>}
        </div>
      </Card>
    </div>
  )
}

NotAutorizedHome.getInitialProps = async () => ({
  namespacesRequired: ['common']
})

NotAutorizedHome.propTypes = {
  t: PropTypes.func.isRequired
}

export default withTranslation('common')(NotAutorizedHome)
