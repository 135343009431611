import React, { useEffect, useState } from 'react'

import Loader from '@components/Loader'
import AutorizedHome from '@components/AutorizedHome'
import NotAutorizedHome from '@components/NotAutorizedHome'

import { useAuth } from '@utils/auth'

const Home = () => {
  const { getIfIsAuthorizedPage } = useAuth()
  const [isAuthorizedReferrer, setIsAuthorizedReferrer] = useState(0)

  useEffect(async () => {
    if (isAuthorizedReferrer === 0) {
      const isAuthorizedR = await getIfIsAuthorizedPage()
      setIsAuthorizedReferrer(isAuthorizedR)
    }
  },[isAuthorizedReferrer])

  return (
    isAuthorizedReferrer === 0
    ? <Loader />
    : isAuthorizedReferrer
      ? <AutorizedHome />
      : <NotAutorizedHome />
  )
}

export default Home
