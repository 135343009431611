import React, { useState, useEffect } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import { isFieldValid } from '@utils/form'
import { FormControl } from '@material-ui/core'

const Select = ({
  autoFocus,
  className,
  defaultValue,
  disabled,
  error,
  fullWidth,
  helperText,
  inputRef,
  label,
  name,
  onChange,
  options = [],
  required,
  value: initValue,
}) => {
  const [value, setValue] = useState(initValue || '')
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    onChange && onChange(name, value, hasError)
  }, [hasError])

  const handleChange = (event) => {
    const value = event.target.value
    setValue(value)
    onChange && onChange(name, value, hasError)
  }

  const handleOnBlur = () => {
    const errorValue = isFieldValid('', required, value)
    setHasError(!errorValue)
  }

  return (
    <div className={`CommonInputContainer ${className}`}>
      <FormControl disabled={disabled} style={{ width: '100%' }}>
        <TextField
          autoComplete="false"
          autoFocus={autoFocus || false}
          defaultValue={defaultValue}
          disabled={disabled}
          error={error || hasError}
          fullWidth={fullWidth || true}
          helperText={helperText}
          id={name}
          inputRef={inputRef}
          label={label}
          margin="normal"
          name={name}
          onChange={e => handleChange(e)}
          onBlur={handleOnBlur}
          required={required}
          select
          value={value}
          variant="outlined"
        >
          {options.map((option, idx) => {
            return <MenuItem key={idx} value={option.key}>{option.value}</MenuItem>
          })}
        </TextField>
      </FormControl>
    </div>
  )
}

export default Select
