import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'

import { useAuth } from '@utils/auth'
import { withTranslation } from '@utils/i18n'

import Card from '@components/Card'
import Text from '@components/common/Text'
import Button from '@components/common/Button'
import css from './index.module.scss'
import Loader from '@components/Loader'

const SCREEN_PATH = `/images/screen.png`
const AutorizedHome = ({ t }) => {
  const { getIdToken, user } = useAuth()

  const [loggedIn, setLoggedIn] = useState('')

  const benefitsList = [
    t('rates'),
    t('safe_reliable'),
    t('creation'),
    t('payments'),
    t('any_bank')
  ]

  useEffect(async () => {
    getIfLoggedIn()
  }, [user, loggedIn])

  useEffect(async () => {
    getIfLoggedIn()
  }, [user])

  const getIfLoggedIn = async () => {
    if (!loggedIn && user) {
      const tokenString = await getIdToken()
      setLoggedIn(!!tokenString)
    } else if (loggedIn === '' && user === false) {
      setLoggedIn(false)
    } else if (user === false && loggedIn) {
      setLoggedIn(false)
    }
  }

  if (user === null) {
    return <Loader/>
  } else {
    return (
      <div className={css.container}>
        <div className={css.title}>
          <Text component="h1" variant="h2" align="center" corlor="secondary">
            {t('receive')}
          </Text>
        </div>
        <Card style={['secondaryColor', 'center', 'radius10', 'shadow']}>
          <div className={css.containerCard}>
            <Text gutterBottom variant="h5" component="h2" color="terciary">
              {t('pinguino_account')}
            </Text>
            <div className={css.contentList}>
              <div className={css.imgContainer}>
                <img src={SCREEN_PATH} />
              </div>
              <div className={css.list}>
                <ul className={css.listContent}>
                  {benefitsList.map((benefit, idx) => {
                    return (
                      <li key={idx}>
                        <Text  component="p" color="terciary">
                          {benefit}
                        </Text>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            {loggedIn === false && <Link href="signup"><a><Button color="terciary">{t('create_account')}</Button></a></Link>}
          </div>
        </Card>
      </div> 
    )
  }
}

AutorizedHome.getInitialProps = async () => ({
	namespacesRequired: ['common']
})

AutorizedHome.propTypes = {
	t: PropTypes.func.isRequired
}

export default withTranslation('common')(AutorizedHome)
