import React from 'react'

import css from './index.module.scss'

const BlockDescription = ({ imgUrl, iconUrl, title, text }) => {
  return (
    <div className={css.container}>
      <img className={css.avatar}
        src={imgUrl}
      />
      <div className={css.title}>
        <img className={css.icon}
          src={iconUrl}
        />
        {title}
      </div>
      <div className={css.text}>
        {text}
      </div>
    </div>
  )
}

export default BlockDescription