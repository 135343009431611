import React from 'react'
import classNames from 'classnames'

import { Card as MaterialCard } from '@material-ui/core/'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles (() => ({
  center: {
    textAlign: 'center'
  },
  size510: {
    width: '510px'
  },
  size600: {
    width: '600px'
  },
  primaryColor: {
    background: '#fff'
  },
  secondaryColor: {
    background: '#0398FF'
  },
  radius10: {
    borderRadius: '10px'
  },
  shadow: {
    boxShadow: '-10px 8px 5px 1px rgba(0,0,0,0.10)'
  },
  transparent: {
    boxShadow: 'none !important',
    border:'none !important'
  },
  editMode: {
    overflowY: 'auto',
    maxHeight: '500px'
  }
}))

const Card = ({ style, children, rest }) => {
  const classes = useStyles()
  const styleList = Array.isArray(style) ? style : [style]
  const styleArray = styleList.map(style => classes[style])
  return (
    <MaterialCard 
      className={classNames(styleArray)}
      {...rest}
    >
      <CardContent>
          {children}
      </CardContent>
    </MaterialCard>
  )
}

export default Card

